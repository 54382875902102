import React, { useRef, useState, useCallback} from 'react';
import Particles from "react-particles";
import { loadFull } from "tsparticles";
import logo from '../assets/logo_dang.png';

const ParticleBG = () => {
    const particlesInit = useCallback(async engine => {
        await loadFull(engine);
    }, []);

    const particlesLoaded = useCallback(async container => {
    }, []);

    return (
        <Particles
            id="tsparticles"
            
            init={particlesInit}
            loaded={particlesLoaded}
            options={{
                fpsLimit: 244,
                interactivity: {
                    events: {
                        onClick: {
                            enable: false,
                            mode: "push",
                        },
                        onHover: {
                            enable: true,
                            mode: "repulse",
                        },
                        resize: true,
                    },
                    modes: {
                        push: {
                            quantity: 4,
                        },
                        repulse: {
                            distance: 200,
                            duration: 0.4,
                        },
                    },
                },
                particles: {
                    color: {
                        value: "#ffffff",
                    },
                    links: {
                        color: "#ffffff",
                        distance: 150,
                        enable: false,
                        opacity: 0.5,
                        width: 1,
                    },
                    collisions: {
                        enable: false,
                    },
                    move: {
                        enable: true,
                        "out_mode": "out",
                        "bounce": false,
                        "direction": "top-right",
                        random: true,
                        speed: 8,
                        straight: false,
                    },
                    number: {
                        density: {
                            enable: true,
                            area: 800,
                        },
                        value: 10,
                    },
                    opacity: {
                        value: 0.3,
                    },
                    shape: {
                        type: "image",
                        image: {
                            src: "https://media.discordapp.net/attachments/1015771791559491624/1098202321348993074/green.png",
                            width: 100,
                            height: 100,
                        },
                    },
                    size: {
                        value: { min: 5, max: 13 },
                    },
                }
            }}
        />
    )
}

export default ParticleBG;