import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import './css/index.css';
import reportWebVitals from './reportWebVitals';
import Main from './Main';
import { HelmetProvider } from 'react-helmet-async';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <React.StrictMode>
        <BrowserRouter>
            <HelmetProvider>
                <Main />
            </HelmetProvider>
        </BrowserRouter>
    </React.StrictMode>
);

reportWebVitals();
