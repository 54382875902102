import React from 'react';
import '../css/App.css';

function BottomBar() {
    return (
        <section className="py-[71px] px-4 md:px-24">
            <div className="justify-center mx-auto max-w-screen-xl">
                <div className="justify-between mb-25 md:px-24">
                    <ul className="grid">
                        <li className="justify-between">
                            <h2 className="text-xs text-center text-[#272629]">
                                <a href='/'>순당무VOD</a>™. All Rights Reserved.</h2>
                            <h2 className="text-xs text-center text-[#272629]">Made by _async</h2>
                        </li>
                    </ul>
                </div>
            </div>
        </section>
    );
}
  
export default BottomBar;