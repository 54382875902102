import YouTube from "react-youtube";

function CreatorOverview({ logo, name, subscribers, description }) {
  return (
    <div className="flex md:max-w-460px mr-4 md:mr-[40px] mb-4 md:mb-[20px]">
      <div className="w-[91px] h-[91px] mr-[20px]">
        <img
          src={logo}
          loading="eager"
          alt="John Carter - Creator X Webflow Template"
          className="rounded-full block max-w-full w-full h-auto align-middle border-0 object-cover overflow-hidden"
        />
      </div>
      <div className="font-thicc">
        <div className="flex flex-wrap items-center space-x-3 mb-[8px]">
          <h1 className="title mr-2 md:mr-17 mb-0 text-[20px] md:text-[28px] font-bold leading-tight">
            {name}
          </h1>
          <div className="px-2 md:px-4 py-1 md:py-2 rounded-full bg-gray-900 text-[#DADADA] text-[10px] md:text-[13px] font-bold leading-tight uppercase tracking-wider">
            {subscribers}
          </div>
        </div>
        <p className="mb-0 text-[12px] md:text-base">{description}</p>
      </div>
    </div>
  );
}

function Banner({videoId, logo, name, subscribers, description}) {
  return (
    <ul className="grid">
      <li className="justify-between">
        <CreatorOverview 
            logo={logo} 
            name={name} 
            subscribers={subscribers}
            description={description}
        /> 
      </li>
      <li className="pt-[20px]">
        <YouTube
          videoId={videoId}
          opts={{
            height: "601",
            width: "100%",
          }}
          className="drop-shadow-2xl overflow-hidden rounded-[5px] block w-full h-full align-middle border-0 object-cover"
        />
      </li>
    </ul>
  );
}

export default Banner;
