import React, { useState } from 'react';
import axios from 'axios';

const UploadPage = () => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [uploadMessage, setUploadMessage] = useState('');

  const handleFileSelect = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const chunkSize = 99 * 1024 * 1024; // 99MB

  const handleUpload = async () => {
    if (selectedFile) {
      setIsUploading(true);
      setUploadSuccess(false);
      const totalChunks = Math.ceil(selectedFile.size / chunkSize);

      for (let chunkIndex = 0; chunkIndex < totalChunks; chunkIndex++) {
        const start = chunkIndex * chunkSize;
        const end = start + chunkSize < selectedFile.size ? start + chunkSize : selectedFile.size;
        const chunk = selectedFile.slice(start, end);
        const formData = new FormData();
        formData.append('file', chunk, `${selectedFile.name}.${chunkIndex}`);
        
        try {
          await axios.post('https://soondangmoo.com/upload_soondangmoo_only', formData, {
            headers: { 'X-Chunk-Index': chunkIndex, 'X-Total-Chunks': totalChunks }
          });
          console.log(`${chunkIndex + 1}번째 파일 업로드 완료`);
        } catch (error) {
          console.error(`파일 업로드 실패 (청크 인덱스: ${chunkIndex}):`, error);
          setIsUploading(false);
          setUploadSuccess(false);
          setUploadMessage('파일 업로드 실패');
          return;
        }
      }

      setIsUploading(false);
      setUploadSuccess(true);
      setUploadMessage('파일이 성공적으로 업로드되었습니다.');
    }
  };

  return (
    <div className="flex items-center justify-center h-screen">
      <div className="w-1/3">
        <h1 className="text-3xl mb-4">파일 업로드</h1>
        <input type="file" onChange={handleFileSelect} className="mb-4 p-2 border border-gray-300" />
        <button onClick={handleUpload} className="ml-[30px] bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
          업로드
        </button>
        {isUploading && (
          <div className="mt-4 bg-yellow-100 border border-yellow-400 text-yellow-700 px-4 py-3 rounded">
            업로드 중...
          </div>
        )}
        {uploadSuccess && (
          <div className="mt-4 bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded">
            {uploadMessage}
          </div>
        )}
      </div>
    </div>
  );
};

export default UploadPage;
