import { useEffect, useState } from 'react';
import Videos from './Videos';

function ClipView() {
    const [sortFlag, setSortFlag] = useState(0);
    const [videoList, setVideoList] = useState([]);

    const sorter = (videolist) => {
        if (sortFlag === 0)
            return videolist.sort((a, b) => b["originalDate"] - a["originalDate"]);
        else if (sortFlag === 1)
            return videolist.sort((a, b) => b["view"] - a["view"]);
        else if (sortFlag === 2)
            return videolist.sort((a, b) => a["originalDate"] - b["originalDate"]);
    }
    
    useEffect(() => {
        const requestOptions = {
            method: 'GET',
            "Content-Type": 'application/x-www-form-urlencoded'
        };
        fetch(`https://api.dangmoo.com/getFileList?type=3`, requestOptions)
            .then(response => response.json())
            .then(result => {
                const sortedList = sorter(result["list"]);
                setVideoList(sortedList);
            })
            .catch(error => console.log('error'. error));
    }, [sortFlag]);

  return (
    <div className="bg-[#EDEEF7]">
        <section className="py-[30px] px-4 md:px-24">
            <div className="justify-center mx-auto max-w-screen-xl">
                <div className="justify-between mb-[30px] md:mb-[30px]">
                    <ul className="grid">
                        <li className="justify-between">
                            <div className="flex md:max-w-460px mr-4 md:mr-[40px] mb-4 md:mb-[20px]">
                                <div className="font-thicc flex items-center justify-between gap-4">
                                    <div>
                                        <h1 className="text-[16px] md:text-[21px] text-[#272629] font-bold">클립</h1>
                                    </div>
                                    <div className="flex text-[16px] md:text-[16px]">
                                        <button 
                                            className='bg-[#ffffff] hover:bg-[#DFDFDF] focus:bg-[#DFDFDF] text-black ring-1 ring-[#D1D5DB] rounded-tl-lg rounded-bl-lg py-[2px] px-2'
                                            onClick={() => setSortFlag(0)}    
                                        >
                                            최신순
                                        </button>
                                        <button className='bg-[#ffffff] hover:bg-[#DFDFDF] focus:bg-[#DFDFDF] text-black ring-1 ring-[#D1D5DB] py-[2px] px-2'
                                            onClick={() => setSortFlag(1)}    
                                        >
                                            조회수순
                                        </button>
                                        <button className='bg-[#ffffff] hover:bg-[#DFDFDF] focus:bg-[#DFDFDF] text-black ring-1 ring-[#D1D5DB] rounded-tr-lg rounded-br-lg py-[2px] px-2'
                                            onClick={() => setSortFlag(2)}    
                                        >
                                            오래된순
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li className="gap-x-2 md:gap-x-4">
                            <Videos list={videoList}/>
                        </li>
                    </ul>
                </div>
            </div>
        </section>
    </div>
  );
}

export default ClipView;