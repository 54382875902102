import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import '../css/App.css';

// const Game1 = () => {
//     const [sequence, setSequence] = useState([]);
//     const [userSequence, setUserSequence] = useState([]);
//     const [gameState, setGameState] = useState('notStarted'); // notStarted | showing | waitingUser | lost
  
//     useEffect(() => {
//       if (gameState === 'showing') {
//         setTimeout(() => {
//           setUserSequence([]);
//           setGameState('waitingUser');
//           console.log('waitingUser');
//         }, sequence.length * 300);
//       }
//     }, [gameState, sequence]);
  
//     const startGame = () => {
//       setUserSequence([]);
//       setSequence([randomTile()]);
//       setGameState('showing');
//       console.log('showing');
//     };
  
//     const tileClicked = (tile) => {
//         if (gameState !== 'waitingUser') 
//         {
//             console.log(gameState);
//             return;
//         }
      
//         const newUserSequence = [...userSequence, tile];
      
//         if (newUserSequence.length <= sequence.length && sequence[newUserSequence.length - 1] !== tile) {
//         //   setGameState('lost');
//           return;
//         }
      
//         setUserSequence(newUserSequence);
      
//         if (newUserSequence.length === sequence.length && newUserSequence.every((val, index) => val === sequence[index])) {
//           setGameState('showing');
//           setSequence(prevSeq => {
//             const availableTiles = [...Array(9).keys()].filter(tile => !prevSeq.includes(tile));
//             return [...prevSeq, availableTiles[Math.floor(Math.random() * availableTiles.length)]];
//           });
//         }
//       };
      
  
//     const randomTile = () => {
//         const availableTiles = [...Array(9).keys()].filter(tile => !sequence.includes(tile));
//         return availableTiles[Math.floor(Math.random() * availableTiles.length)];
//       };
  
//     return (
//     <div className='pt-[60px]'>
//         <h1 className="text-3xl mb-4">Memory Game</h1>
//         <div className="text-xl mb-4">
//         <p>현재 라운드: {sequence.length}</p>
//         <p>맞은 타일 개수: {userSequence.length}</p>
//         <p>남은 타일 개수: {sequence.length - userSequence.length}</p>
//         </div>
//         <div className="flex justify-center">
//             <div className="grid grid-cols-3">
//             {[...Array(9)].map((_, i) => (
//                 <div
//                 key={i}
//                 className={`tile w-32 h-32 p-4 border m-1 ${gameState === 'showing' && sequence.includes(i) ? 'bg-yellow-300' : 'bg-blue-300'}`}
//                 onClick={() => tileClicked(i)}
//                 ></div>
//             ))}
//             </div>
//         </div>
//         <button onClick={startGame} className="p-2 bg-green-500 text-white mt-4">Start Game</button>
//     </div>
//     );
// };
const Game1 = () => {
    const [level, setLevel] = useState(1);
    const [randomNumber, setRandomNumber] = useState('');
    const [userInput, setUserInput] = useState('');
    const [isShowingNumber, setIsShowingNumber] = useState(false);
    const [gameStarted, setGameStarted] = useState(false);
    const [progress, setProgress] = useState(100);
    const [gameOver, setGameOver] = useState(false);

    const preventCopy = (e) => {
        e.preventDefault();
    };

    useEffect(() => {
        document.addEventListener('copy', preventCopy);
        
        if (isShowingNumber) {
            const interval = setInterval(() => {
                setProgress(prev => prev - 100/30); // 3초 동안 매 100ms 마다 줄어듭니다.
            }, 100);

            setTimeout(() => {
                setIsShowingNumber(false);
                setProgress(100);
                clearInterval(interval);
            }, 3000);

            return () => clearInterval(interval);
        }

        return () => {
            document.removeEventListener('copy', preventCopy);
        };
        
    }, [isShowingNumber]);

    function handleSubmit() {
        if (userInput === randomNumber) {
            setLevel(level + 1);
            setUserInput('');
            setIsShowingNumber(true);
            setRandomNumber(generateNumber(level + 1));
        } else {
            setGameOver(true);
            setGameStarted(false);
        }
    }
    
    function generateNumber(length) {
        let number = '';
        for (let i = 0; i < length; i++) {
            number += Math.floor(Math.random() * 10);
        }
        return number;
    }

    function startGame() {
        setGameOver(false);
        setRandomNumber(generateNumber(level));
        setIsShowingNumber(true);
        setGameStarted(true);
    }

    function handleSubmit() {
        if (userInput === randomNumber) {
            setLevel(level + 1);
            setUserInput('');
            setIsShowingNumber(true);
            setRandomNumber(generateNumber(level + 1));
        } else {
            setUserInput('');
            setGameStarted(false);
            setRandomNumber('');
            setGameOver(true);
        }
    }

    return (
        <>
            <Helmet>
                <title>숫자게임</title>
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <meta property="og:site_name" content="순당무VOD"/>
                <meta property="og:title" content={"순당무VOD 숫자게임"}/>
                <meta property="og:description" content={"순당무VOD 숫자게임"}/>
            </Helmet>
            <div className="min-h-screen flex flex-col items-center justify-center">
                <h1 className="text-3xl mb-4">숫자 게임</h1>
                <p className="mb-4">현재 라운드: {level}</p>

                {gameOver && (
                    <div className="text-red-500 mb-4">
                        게임 오버! 점수: {level - 1}
                    </div>
                )}

                {!gameStarted && 
                    <button 
                        className="bg-green-500 text-white p-2 mt-4"
                        onClick={startGame}
                    >
                        게임 시작
                    </button>
                }

                {gameStarted && (
                    <>
                        {isShowingNumber ? (
                            <div className="text-2xl mb-4 no-select">
                                {randomNumber}
                            </div>
                        ) : (
                            <div>
                                <input
                                    className="border p-2 mr-2"
                                    type="text"
                                    value={userInput}
                                    onChange={(e) => setUserInput(e.target.value)}
                                />
                                <button
                                    className="bg-blue-500 text-white p-2"
                                    onClick={handleSubmit}
                                >
                                    ㄱㄱ
                                </button>
                            </div>
                        )}
                        
                        {isShowingNumber && (
                            <div className="relative w-64 h-4 bg-gray-300 mt-4">
                                <div className="absolute left-0 top-0 h-4 bg-green-500" style={{ width: `${progress}%` }}></div>
                            </div>
                        )}
                    </>
                )}
            </div>
        </>
    );
}
export default Game1;
