import { useEffect, useState, useRef } from "react";
import React from 'react';
import "./css/App.css";
import loading from './assets/loading.png';
import { Helmet } from 'react-helmet-async';
import YTlogo from './assets/channels4_profile.jpg';
import Comment from './components/Comment';
import ParticleBG from './components/ParticleBG';
import ReactPlayer from 'react-player';
import Hls from 'hls.js';

function VideoView(props) {
  const [videoInfo, setVideoInfo] = useState({});
  const [isWindow, setIsWindow] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const playerRef = useRef(null);
  const [video_url, setvideo_url] = useState("");

  useEffect(() => {
    setIsWindow(true);

    const requestOptions = {
      method: 'GET',
      "Content-Type": 'application/x-www-form-urlencoded',
      mode: 'no-cors'
    };

    fetch(`https://api.dangmoo.com/get_hls/${props.index}`)
        .then(response => response.text())
        .then(result => { 
          let path = JSON.parse(result);
          setvideo_url(path.hls_path); 
          console.log(path.hls_path);
        })
        .catch(error => console.log('error'. error));

    fetch(`https://api.dangmoo.com/count/${props.index}`, requestOptions)
        .then(response => response.json())
        .catch(error => console.log('error'. error));
    
    fetch(`https://api.dangmoo.com/videoInfo/${props.index}`)
      .then(response => response.text())
      .then(result => { setVideoInfo(JSON.parse(result));})
      .catch(error => console.log('error'. error));

  }, []);

  const handleClick = async () => {
    if (!playerRef.current) {
      alert('영상을 먼저 재생해주세요.');
      return;
    }

    const formattedStartTime = prompt("시작 시간을 입력하세요. (예시: 00:00:00)");
    const formattedEndTime = prompt("종료 시간을 입력하세요. (최대 60초, 예시: 00:01:00)");
    const title = prompt("클립의 제목을 입력하세요.");

    if (formattedEndTime === null || title === null) {
      return;
    }

    const videoIndex = props.index; // 예시
    const apiUrl = `https://api.dangmoo.com/makeClip?video_index=${videoIndex}&start_time=${formattedStartTime}&end_time=${formattedEndTime}&title=${title}`;

    try {
      const response = await fetch(apiUrl);

      if (response.status === 200) {
        alert('클립 생성 요청 성공. 잠시 후 클립이 생성됩니다.');
      } else {
        alert('클립 생성 실패');
      }
    } catch (error) {
      console.error('API 호출 중 오류 발생:', error);
    }
  };

  const convertTimeToSeconds = (time) => {
    const [hours, minutes, seconds] = time.split(':').map(Number);
    return hours * 3600 + minutes * 60 + seconds;
  }
  
  const handleTimeClick = (timeString) => {
    const timeInSeconds = convertTimeToSeconds(timeString);
    if (playerRef.current) {
      playerRef.current.seekTo(timeInSeconds);
    }
  };


  return (
    <>
      <ParticleBG/>
      <Helmet>
        <title>{props.title}</title>
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta property="og:image" content={loading}/>
        <meta property="og:site_name" content="순당무VOD"/>
        <meta property="og:title" content={"순당무VOD" + props.title}/>
        <meta property="og:description" content={props.title + " 영상 다시보기"}/>
        <meta property="og:type" content="video.other"/>
        <meta property="og:image" content={"https://api.dangmoo.com/v1/thumbnails/" + props.index}/>
        <meta property="og:image:secure_url" content={"https://api.dangmoo.com/v1/thumbnails/" + props.index}/>
        <meta property="og:image:type" content="image/png"/>
        <meta property="og:image:width" content="1280"/>
        <meta property="og:image:height" content="720"/>
        <meta property="og:url" content={"https://api.dangmoo.com/view/thumbnails/" + props.index}/>
        <meta property="og:video" content={"https://api.dangmoo.com/view/thumbnails/" + props.index}/>
        <meta property="og:video:url" content={"https://api.dangmoo.com/view/thumbnails/" + props.index}/>
        <meta property="og:video:secure_url" content={"https://api.dangmoo.com/view/thumbnails/" + props.index}/>
        <meta property="og:video:type" content="video/mp4"/>
        <meta property="og:video:width" content="1280"/>
        <meta property="og:video:height" content="720" />
      </Helmet>
      <div className="bg-[#EDEEF7]">
        <section className="pt-[60px] py-[91px]">
            <div className="justify-center mx-auto max-w-screen-xl px-4">
                <div className="justify-between mb-25">
                    <ul className="grid">
                        <li className="justify-between">
                          <div className={`flex`}>
                            {/* <video className={`z-20 rounded-[5px] block align-middle border-0 object-cover overflow-hidden`} src={"https://soondangmoo.com/view/" + props.index} type='video/mp4' controls></video> */}
                            {/* <video className={`z-20 rounded-[5px] block align-middle border-0 object-cover overflow-hidden`} src={"https://soondangmoo.com/view/" + props.index} type='video/mp4' data-setup='{"aspectRatio":"16:9"}' controls></video> */}
                            <ReactPlayer
                              ref={playerRef}
                              url={video_url}
                              // url={`https://api.dangmoo.com/20190910_479466521_League of Legends_aram4519_VIDEO/output.m3u8`}
                              controls
                              playing={isPlaying}
                              width={"100%"}
                              height={"100%"}
                              className={`z-20 rounded-[5px] block align-middle border-0 object-cover overflow-hidden`}
                            />
                          </div>
                        </li>
                        <li className="flex justify-between">
                          <div className="flex justify-start">
                            <img src={YTlogo} className="w-[40px] h-[40px] rounded-full mt-[18px] mr-1"/>
                            <h2 className="text-center text-[16px] md:text-[21px] text-[#272629] font-bold mt-6">
                              {props.title}
                            </h2>
                            <a href={`https://api.dangmoo.com/download/${props.index}`} download={props.title + '.mp4'}>
                              <svg className="text-[#717171] w-5 h-auto ml-2 pt-[30px]" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3" />
                              </svg>
                            </a>
                          </div>
                          <div className="mt-[22px]">
                            <button
                              onClick={handleClick}
                              className="bg-blue-500 hover:bg-blue-600 text-white font-bold p-1 px-4 rounded-md"
                            >
                              클립 생성
                            </button>
                          </div>
                          
                        </li>
                        <li className="justify-between">
                          <p className="text-[#616161] text-sm mt-3 mb-3">{`조회수 ${videoInfo["viewcount"]}회 • ${videoInfo["date"]}`}</p>                          
                        </li>
                        <li>
                          <div className="w-full h-[1px] bg-gray-300 mb-3 mt-3"/>
                          <Comment videoIndex={props.index} onTimeClick={handleTimeClick}/>
                        </li>
                    </ul>
                </div>
            </div>
        </section>
      <div>
          <div className="w-full h-[1px] bg-gray-300"></div>
      </div>
    
</div>
</>
);
}

export default VideoView;