import { useRef, useState } from 'react';
import '../css/App.css';
import logo from '../assets/loading.png';

const VideoItems = ({ list }) => {
    const [loading, setLoading] = useState(true);
    const counter = useRef(0);
    const imageLoaded = () => {
        counter.current += 1;
        if (counter.current >= list.length) {
            setLoading(false);
        }
    }

    return [
        loading &&
        [...Array(16)].map(i => {
            return (
                <div key={Math.random()} className="rounded-lg" style={{ display: loading ? "block" : "none" }}>
                    <div className="overflow-hidden">
                        <div className="overflow-hidden relative">
                            <img
                                src={logo}
                                width="480"
                                height="270"
                            />
                        </div>
                        <div className="py-1 pb-5">
                            <div className="text-[#868686] font-[Roboto] text-base font-medium mb-2 truncate" style={{ backgroundColor: "#F0F0F0" }}>&nbsp;</div>
                            <p className="text-[#616161] text-sm" style={{ backgroundColor: "#F0F0F0" }}>
                                &nbsp;
                            </p>
                        </div>
                    </div>
                </div>
            );
        }),
        list.map((item, i) => {
            return (
                <div key={i} className="rounded-lg" style={{ display: loading ? "none" : "block" }}>
                    <div className="rounded-lg overflow-hidden">
                        <div className="rounded-lg overflow-hidden relative">
                            <a href={`/watch?index=${item.index}&title=${item.filename}`}>
                                <img
                                    onLoad={imageLoaded}
                                    alt="thumbnail"
                                    width="480"
                                    height="270"
                                    src={`https://api.dangmoo.com/v1/thumbnails/${item.thumbnail}`}
                                />
                            </a>
                            <div className="rounded bg-[#000000] absolute text-white bottom-0 right-0 mr-2 mb-2">
                                <span className="content-center justify-center p-1 text-center">
                                    { item.duration }
                                </span>
                            </div>
                        </div>
                        <div className="py-1 pb-5 relative overflow-hidden">
                            <a href={`/watch?index=${item.index}&title=${item.filename}`}>
                                <div className="text-[#000000] font-[Roboto] text-base font-medium mb-2 ">{item.filename}</div>
                            </a>
                            <p className="text-[#616161] text-sm">
                                조회수 {item.view}회 • {item.date}
                            </p>
                            {/* 다운로드 */}
                            <div className="absolute text-white bottom-[14px] right-0 mr-0 mb-2">
                                <a href={`https://api.dangmoo.com/download/${item.index}`} download={item.filename + '.mp4'}>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="text-[#717171] w-5 h-auto">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3" />
                                    </svg>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            );
        })
    ];
};

const Videos = ({ list }) => {
    return (
        <div id="cards" className="grid grid-flow-row gap-4 text-neutral-600 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-4 px-2 py-4">
            <VideoItems list={list} />
        </div>
    );
};

export default Videos;