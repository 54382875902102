import React, { useState, useEffect } from "react";

const TimeConverter = ({ timeString }) => {  
    let t = new Date(Date.parse(timeString));
    t.setMinutes(t.getMinutes() - 2);
    t.setSeconds(t.getSeconds() + 10);
    return t.toLocaleString();
};

const Reply = ({
  reply,
  setCurrentParentId,
  setIsReplyOpen,
  isReplyOpen,
  currentParentId,
  handleSubmit,
  content,
  setContent,
}) => {
  return (
    <div className="bg-[#E2E8F0] rounded-md ml-6 my-1 overflow-y-auto md:ml-6 pt-4 pl-4">
      <div className="flex justify-between items-center">
        <h4 className="text-sm font-bold">{reply.user.username}</h4>
        <p className="text-xs text-gray-500 mr-4">
          <TimeConverter timeString={`${reply.created_at}`} />
        </p>
      </div>
      <p
        className="mt-2 text-sm"
        style={{ whiteSpace: "pre-wrap", wordWrap: "break-word" }}
      >
        {reply.content}
      </p>
      <button
        className="text-xs font-semibold text-blue-500 mt-2"
        onClick={() => {
          setCurrentParentId(reply.comment_id);
          setIsReplyOpen(true);
        }}
      >
        reply
      </button>
      {isReplyOpen && currentParentId === reply.comment_id && (
        <ReplyForm
          handleSubmit={handleSubmit}
          content={content}
          setContent={setContent}
          setIsReplyOpen={setIsReplyOpen}
        />
      )}
      {reply.replies.map((nestedReply) => (
        <Reply
          key={nestedReply.comment_id}
          reply={nestedReply}
          setCurrentParentId={setCurrentParentId}
          setIsReplyOpen={setIsReplyOpen}
          isReplyOpen={isReplyOpen}
          currentParentId={currentParentId}
          handleSubmit={handleSubmit}
          content={content}
          setContent={setContent}
        />
      ))}
    </div>
  );
};

const CommentComponent = ({
  comment,
  setCurrentParentId,
  setIsReplyOpen,
  isReplyOpen,
  currentParentId,
  handleSubmit,
  content,
  setContent,
  onTimeClick
}) => {
  const contentWithTimeLinks = (content) => {
    const regex = /\b([0-2]?[0-9]:[0-5][0-9]:[0-5][0-9])\b/g;
    const parts = content.split(regex);

    return parts.map((part, index) => 
      regex.test(part) ? 
        <span key={index} style={{ color: 'blue', cursor: 'pointer' }} onClick={() => onTimeClick(part)}>{part}</span> : 
        part
    );
  };

  return (
    <div className="rounded-md mb-1 p-4">
      <div className="flex justify-between items-center">
        <h4 className="text-sm font-bold">{comment.user.username}</h4>
        <p className="text-xs text-gray-500">
          <TimeConverter timeString={comment.created_at} />
        </p>
      </div>
      <p
        className="mt-2 text-sm"
        style={{ whiteSpace: "pre-wrap", wordWrap: "break-word" }}
      >
        {contentWithTimeLinks(comment.content)}
      </p>
      <button
        className="text-xs font-semibold text-blue-500 mt-2"
        onClick={() => {
          setCurrentParentId(comment.comment_id);
          setIsReplyOpen(true);
        }}
      >
        reply
      </button>
      {isReplyOpen && currentParentId === comment.comment_id && (
        <ReplyForm
          handleSubmit={handleSubmit}
          content={content}
          setContent={setContent}
          setIsReplyOpen={setIsReplyOpen}
        />
      )}
      {comment.replies.map((reply) => (
        <Reply
          key={reply.comment_id}
          reply={reply}
          setCurrentParentId={setCurrentParentId}
          setIsReplyOpen={setIsReplyOpen}
          isReplyOpen={isReplyOpen}
          currentParentId={currentParentId}
          handleSubmit={handleSubmit}
          content={content}
          setContent={setContent}
        />
      ))}
    </div>
  );
};

const ReplyForm = ({ handleSubmit, content, setContent, setIsReplyOpen }) => {
  return (
    <div className="mt-2">
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          value={content}
          onChange={(e) => setContent(e.target.value)}
          placeholder="Write your reply"
          className="w-full p-2 rounded-md border-2 border-gray-300"
        />
        <div className="flex justify-between items-center gap-5 mt-2 md:gap-5">
          <button
            type="submit"
            className="w-full bg-blue-500 text-white py-2 rounded-md"
          >
            Submit
          </button>
          <button
            type="button"
            onClick={() => {
              setIsReplyOpen(false);
              setContent("");
            }}
            className="w-full bg-gray-500 text-white py-2 rounded-md"
          >
            Cancel
          </button>
        </div>
      </form>
    </div>
  );
};

const CommentSection = ({ videoIndex, onTimeClick  }) => {
  const [content, setContent] = useState("");
  const [commentContent, setCommentContent] = useState("");
  const [comments, setComments] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isReplyOpen, setIsReplyOpen] = useState(false);
  const [currentParentId, setCurrentParentId] = useState(null);
  const sortReplies = (comments) => {
    const repliesMap = new Map();
    const sortedComments = comments
      .filter((comment) => {
        if (comment.parent_id) {
          if (repliesMap.has(comment.parent_id)) {
            repliesMap.get(comment.parent_id).push(comment);
          } else {
            repliesMap.set(comment.parent_id, [comment]);
          }
          return false;
        }
        return true;
      })
      .map((comment) => {
        comment.replies = repliesMap.get(comment.comment_id) || [];
        return comment;
      });

    // Add the 'replies' property to all comments
    comments.forEach((comment) => {
      comment.replies = repliesMap.get(comment.comment_id) || [];
    });

    return sortedComments;
  };

  useEffect(() => {
    fetch(
      `https://api.dangmoo.com/comments?video_index=${videoIndex}&after_index=0`
    )
      .then((res) => res.json())
      .then((data) => {
        setComments(sortReplies(data.comments));
        setIsLoading(false);
      })
      .catch((error) => console.error(error));
  }, [videoIndex]);

  const handleSubmit = (e) => {
    e.preventDefault();

    fetch("https://api.dangmoo.com/comments", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        content,
        video_index: videoIndex,
        parent_id: currentParentId,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.message === "Success") {
          setContent("");
          setIsReplyOpen(false);
          fetch(
            `https://api.dangmoo.com/comments?video_index=${videoIndex}&after_index=0`
          )
            .then((res) => res.json())
            .then((data) => {
              setComments(sortReplies(data.comments));
              setIsLoading(false);
            })
            .catch((error) => console.error(error));
        } else {
          alert("답글 등록에 실패했습니다.");
        }
      })
      .catch((error) => console.error(error));
  };

  const handleSubmit1 = (e) => {
    e.preventDefault();

    fetch("https://api.dangmoo.com/comments", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        content: commentContent,
        video_index: videoIndex,
        parent_id: "0",
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.message === "Success") {
          setCommentContent("");
          // Fetch and update comments after successfully posting a comment
          fetch(
            `https://api.dangmoo.com/comments?video_index=${videoIndex}&after_index=0`
          )
            .then((res) => res.json())
            .then((data) => {
              setComments(sortReplies(data.comments));
              setIsLoading(false);
            })
            .catch((error) => console.error(error));
        } else {
          alert("댓글 등록에 실패했습니다.");
        }
      })
      .catch((error) => console.error(error));
  };

  if (isLoading) return <div>Loading...</div>;

  return (
    <>
      <form onSubmit={handleSubmit1} className="rounded-md">
        <textarea
          value={commentContent}
          onChange={(e) => setCommentContent(e.target.value)}
          required
          maxLength={256}
          className="w-full border border-gray-300 rounded-md focus:outline-none focus:border-blue-500"
          rows="4"
        ></textarea>
        <button
          type="submit"
          className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded-md mt-2"
        >
          댓글 작성
        </button>
      </form>
      <div className="mt-[30px]">
        {comments.map((comment) => (
          <CommentComponent
            key={comment.comment_id}
            comment={comment}
            setCurrentParentId={setCurrentParentId}
            setIsReplyOpen={setIsReplyOpen}
            isReplyOpen={isReplyOpen}
            currentParentId={currentParentId}
            handleSubmit={handleSubmit}
            content={content}
            setContent={setContent}
            onTimeClick={onTimeClick}
          />
        ))}
      </div>
    </>
  );
};

const Comment = ({ videoIndex, onTimeClick }) => {
  return (
    <div className="container mx-auto my-8 px-4 md:px-0">
      <h2 className="text-xl font-bold mb-4">댓글</h2>
      <p className="mb-4"> 댓글 내용이 부적절할 경우, 관리자에 의해 삭제될 수 있습니다.</p>
      <p className="mb-4"> 작성한 댓글은 수정 및 삭제가 불가능합니다.</p>
      <CommentSection videoIndex={videoIndex} onTimeClick={onTimeClick}/>
    </div>
  );
};

export default Comment;