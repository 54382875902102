import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import './css/index.css';
import VideoView from './VideoView';
import 'flowbite';
import Navigation from './components/navtest';
import MainMedia from './components/MainMedia';
import UploadPage from './components/Update';
import BottomBar from './components/BottomBar';
import Game1 from './components/Game1';
import Admin from './components/Admin';

const Main = () => {
    const searchParams = new URLSearchParams(useLocation().search);
    return (
        <>
            <div className="bg-[#EDEEF7] z-10 mt-11">
                <Navigation />
                <Routes>
                    <Route path="/" element={<MainMedia view={"video"}/>}/>
                    <Route path="/clip" element={<MainMedia view={"clip"}/>}/>
                    {/* <Route path="/update" element={<Update />}/> */}
                    <Route path="/upload_page_soondangmoo" element={<UploadPage />}/>
                    <Route path="/game" element={<Game1 />}/>
                    {/* <Route path="/admin" element={<Admin />}/> */}
                    <Route path="/watch" element={<VideoView index={searchParams.get('index')} title={searchParams.get('title')}/>} />
                </Routes>
                <BottomBar/>
            </div>
        </>
    )
}

export default Main;