import { useEffect, useState } from "react";
import Videos from "./Videos";
import YouTube from "react-youtube";
import YTlogo from "../assets/channels4_profile.jpg";
import VodView from "./VodView";
import ClipView from "./ClipView";
import Separator from "./Separator";
import Banner from "./Banner";
import ParticleBG from './ParticleBG';

function RecentCommentList() {
    const [comments, setComments] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        fetch('https://api.dangmoo.com/recent_comments')
            .then(res => res.json())
            .then(data => {
                setComments(data.comments);
                setLoading(false);
            })
            .catch(err => console.log(err));
    }, []);

    if (loading) { return <div>Loading...</div> }

    return (
        <ul role="list" className="divide-y divide-gray-300">
        {comments.map((comment) => (
            <li key={comment.content} className="flex flex-col sm:flex-row justify-between gap-x-6 py-5">
            <div className="flex gap-x-4">
                {/* <img className="h-12 w-12 flex-none rounded-full bg-gray-50" src={person.imageUrl} alt="" /> */}
                <div className="min-w-0 flex-auto">
                <p className="text-sm font-semibold leading-6 text-gray-900">{comment.user.username}</p>
                <p className="mt-1 truncate text-xs leading-5 text-gray-500">{comment.content}</p>
                </div>
            </div>
            <div className="mt-4 sm:mt-0 sm:flex sm:flex-col sm:items-end">
                <p className="text-sm leading-6 text-gray-800 hover:text-blue-500 hover:underline">
                <a href={`/watch?index=${comment.video_index}`}>
                    {comment.video_title}
                </a>
                </p>
                <p className="mt-1 text-xs leading-5 text-gray-500">
                {comment.created_at}
                </p>
            </div>
            </li>
        ))}
        </ul>
    )
}

function RecentClipList() {
    const [clipVideoList, setClipVideoList] = useState([]);
    const [loading, setLoading] = useState(true);
    
    const sorter2 = (videolist) => {
        // newest first
        return videolist.sort((a, b) => b["originalDate"] - a["originalDate"]);
    }
    const requestOptions = {
        method: 'GET',
        "Content-Type": 'application/x-www-form-urlencoded'
    };
    useEffect(() => {
        fetch(`https://api.dangmoo.com/getFileList?type=3`, requestOptions)
        .then(response => response.json())
        .then(result => {
            // sort clips
            const sortedList = sorter2(result["list"]);
            // only show 5 clips
            sortedList.splice(4);
            setClipVideoList(sortedList);
            setLoading(false);
        })
        .catch(error => console.log('error'.error));
    }, []);    

    if (loading) {
        return (
            <section className="py-[30px] px-4 md:px-24">
                <div className="justify-center mx-auto max-w-screen-xl">
                    <div className="justify-between ">
                        <ul className="grid">
                            <li className="justify-between">
                                <div className="flex md:max-w-460px mr-4 md:mr-[40px] mb-4 md:mb-[20px]">
                                    <div className="font-thicc flex items-center justify-between gap-4">
                                        <div>
                                                <h1 className="text-[16px] md:text-[21px] text-[#272629] font-bold">최근 클립</h1>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li className="gap-x-2 md:gap-x-4">
                                <div className="grid grid-flow-row sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 xl:grid-cols-1 py-4">
                                    <div className="grid-rows-1">
                                        <div>
                                        </div>
                                    </div>
                                    <div className="grid-rows-2">
                                        <div>Loading...</div> 
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </section>
        ) 
    }

    return (
        <section className="py-[30px] px-4 md:px-24">
            <div className="justify-center mx-auto max-w-screen-xl">
                <div className="justify-between ">
                    <ul className="grid">
                        <li className="justify-between">
                            <div className="flex md:max-w-460px mr-4 md:mr-[40px] mb-4 md:mb-[20px]">
                                <div className="font-thicc flex items-center justify-between gap-4">
                                    <div>
                                            <h1 className="text-[16px] md:text-[21px] text-[#272629] font-bold">최근 클립</h1>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li className="gap-x-2 md:gap-x-4">
                            <div className="grid grid-flow-row sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 xl:grid-cols-1 py-4">
                                <div className="grid-rows-1">
                                    <div>
                                    </div>
                                </div>
                                <div className="grid-rows-2">
                                    <Videos list={clipVideoList}></Videos>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </section>
    )
}

function MainMedia(props) {
  return (
    <>
    <ParticleBG/>
    <div className="bg-[#EDEEF7]">
      <section className="pt-[60px] py-[91px] px-4 md:px-24">
        <div className="justify-center mx-auto max-w-screen-xl">
          <Banner 
            videoId={"qdgTtiVvwmQ"}
            logo={YTlogo}
            name={"@SOONDANGMOO"}
            subscribers={"250K Subscribers"}
            description={"트위치 스트리머 순당무입니다 주로 롤 위주로 게임을 하며 종합게임도 간간히 진행하고있습니다."}
          />
        </div>
      </section>
      <Separator />
      <section className="py-[30px] px-4 md:px-24">
            <div className="justify-center mx-auto max-w-screen-xl">
                <div className="justify-between ">
                    <ul className="grid">
                        <li className="justify-between">
                            <div className="flex md:max-w-460px mr-4 md:mr-[40px] mb-4 md:mb-[20px]">
                                <div className="font-thicc flex items-center justify-between gap-4">
                                    <div>
                                        <h1 className="text-[16px] md:text-[21px] text-[#272629] font-bold">최근 댓글</h1>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li className="gap-x-2 md:gap-x-4">
                            <RecentCommentList />
                        </li>
                    </ul> 
                </div>
                
            </div>
      </section>
      {
        props.view === "video"
        ? 
        <>
            <Separator />
            <RecentClipList />
        </>
        : <></>
      }

      <Separator />
      {
        props.view === "clip"
        ? <ClipView />
        : <VodView />
      }
      <Separator />
    </div>
    </>
  );
}

export default MainMedia;
